class StorageStore {

    static storeDomain;
    static baseName = "/";
    static items = {};
    static localStorageAvailable = null;

    static init(storeDomain, baseName){
        this.localStorageAvailable = null;
        this.storeDomain = storeDomain;
        this.baseName = baseName.endsWith('/') ? baseName.slice(0, -1) : baseName;;
    }
    static setItem(key ,value){
        if (this.isLocalStorageAvailable()) {
            localStorage.setItem(this.prepareKey(key), value)
        } else {
            this.items[this.prepareKey(key)] = value;
        }
    }
    static getItem(key){
        if (this.isLocalStorageAvailable()) {
            return localStorage.getItem(this.prepareKey(key))
        } else {
            if(this.prepareKey(key) in this.items){
                return this.items[this.prepareKey(key)];
            }
            return null;
        }
    }
    static removeItem(key){
        if (this.isLocalStorageAvailable()) {
            localStorage.removeItem(this.prepareKey(key))
        } else {
            delete this.items[this.prepareKey(key)];
        }
    }

    static prepareKey(key){
        return this.storeDomain + "-" + key;
    }
    static isLocalStorageAvailable(){
        if(this.localStorageAvailable === null){
            try {
                let len = localStorage.length;
                if(typeof window.localStorage !== 'undefined'){
                    this.localStorageAvailable = true;
                }else{
                    this.localStorageAvailable = false;
                }
            } catch(e) {
                this.localStorageAvailable = false;
            }
        }
        return this.localStorageAvailable;
    }
    static getBaseName(){
        return this.baseName;
    }
}
export default StorageStore;