import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLocale} from "../../../../../application/store/reducer";
import AppConfig from "../../../../../application/common/AppConfig";
import QRCode from "qrcode";
import {addVoucherToOrder} from "../../../../../application/cart/cartService";
import {getCart} from "../../../../../application/cart/reducer";
import {getLocation} from "../../../../../application/location/reducer";
import {useHistory} from "react-router-dom";
import ErrorFormAlertView from "../../../../common/ErrorFormAlertView";

function VoucherModal(props) {
    const [qrcodeBase64, setQrcodeBase64] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const canvasRef = useRef(null);
    const voucher = props.voucher;
    const product = voucher.product;
    const history = useHistory();
    const addVoucher = async (voucher) => {
        setLoading(true);
        let orderData = {
            address: props.location.address,
            contact: props.cart.contact,
            type: props.cart.type,
            estimated_preparation_at: props.cart.estimated_preparation_at,
            pickup_at: props.cart.pickup_at,
            number: props.cart.number,
            locale: props.locale
        }
        try {

            await props.dispatch(addVoucherToOrder(voucher.code, orderData));
            setLoading(false);
            history.push("/");
        } catch (e) {
            console.log(e);
            setErrors([{
                message: "Nie udało się dodać vouchera."
            }]);
            setLoading(false);
        }
    }
    useEffect(() => {
        if (canvasRef.current) {
            QRCode.toCanvas(canvasRef.current, voucher.code, {width: 250}, (error) => {
                if (error) console.error(error);
            });
        }
    }, []);
    const onShow = () => {
    }


    return (
        <>
            <Modal dialogClassName="modal-sm modal-login" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"voucher-full"}>

                        {/*<div className={"voucher-full-img"}>*/}
                        {/*    {product.image ? (*/}
                        {/*        <img src={product.image}/>*/}
                        {/*    ) : (*/}
                        {/*        <div className={"voucher-full-img-color"} style={{backgroundColor: product.color}}>*/}
                        {/*            <VoucherSVG/>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*    {product.cost ? (*/}
                        {/*        <span className={"points"}>{product.cost} pkt</span>*/}
                        {/*    ) : (*/}
                        {/*        <span className={"points"}>Darmowy</span>*/}
                        {/*    )}*/}
                        {/*    /!*<span className={"valid-time"}>Ważny do: 12.01.25</span>*!/*/}
                        {/*</div>*/}
                        <h5>
                            {product.name}
                        </h5>
                        <p className="card-text">{product.description}</p>
                        <ErrorFormAlertView errors={errors}/>

                        {AppConfig.isUserRegistration() && (
                            <>
                                {voucher.code && (
                                    <>
                                        <div className={"voucher-full-qrcode"}>
                                            <div className={"qrcode"}>
                                                <canvas ref={canvasRef}></canvas>
                                            </div>
                                            <p className={"info"}>
                                                Zeskanuj kod, aby skorzystać w restauracji
                                            </p>
                                        </div>

                                        <div className="horizontal-divider">
                                            <span>lub</span>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <Button
                            className={`btn btn-spinner btn-primary w-100 ${loading ? " loading disabled" : ""}`}
                            onClick={() => addVoucher(voucher)}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Dodaj do koszyka
                        </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: getCart(state.cart),
        location: getLocation(state.location),
        locale: getLocale(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(VoucherModal))
