import React, {useState} from 'react';
import {connect} from "react-redux";
import {
    getItemGroups,
    getItems,
    getMenuPending,
    getMenus,
    getModifierGroups,
    isStoreOpen
} from "../../../../application/menu/reducer";
import {Modal} from "react-bootstrap";
import {getCart, getOrderPending} from "../../../../application/cart/reducer";
import ItemHelper from "../../../../application/common/ItemHelper";
import {getLocation} from "../../../../application/location/reducer";
import IframeWidget from "../../../utils/IframeWidget";
import {withTranslation} from "react-i18next";
import MenuShowItemSingleV3 from "../components/v2/MenuShowItemSingleV3";
import AppConfig from "../../../../application/common/AppConfig";
import MenuEditItemStateHelper from "../components/MenuShowItem/MenuEditItemStateHelper";

const MenuEditItem = (props) => {

    const {items, t} = props;
    let id = props.orderItem.item_id;
    let item = ItemHelper.getItem(items, id);
    if (item === null) {
        return null;
    }
    let productImgView = null;
    if (item.image_link) {
        productImgView = (
            <div className="product-item-img">
                <img src={item.image_link.default}/>
            </div>
        )
    }
    let itemGroup = ItemHelper.getItemGroup(props.itemGroups, item.id);
    let stateItem = MenuEditItemStateHelper.parseOrderItemToStateItem(props.orderItem);

    if (!AppConfig.isDesignV2() && !AppConfig.isDesignV3()) return null;
    return (
        <Modal dialogClassName={"modal-full modal-product modal-product-full"} show={true}
               onHide={props.onHide} onShow={IframeWidget.openModal}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        {productImgView}
                    </div>
                    <div className={"col-md-6 modal-product-full-right"}>
                        <MenuShowItemSingleV3 itemId={item.id} itemGroup={itemGroup} onUpdate={props.onHide}
                                              stateItem={stateItem}
                                              orderItemId={props.orderItem.id}
                                              onHide={props.onHide}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        itemGroups: getItemGroups(state.menu),
        menus: getMenus(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        menuPending: getMenuPending(state.menu),
        isStoreOpen: isStoreOpen(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuEditItem))