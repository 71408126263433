import React, {useState} from 'react';
import {connect} from "react-redux";
import {getCart} from '../../../application/cart/reducer';
import {updateOrderCoupon} from "../../../application/cart/cartService";
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {withTranslation} from "react-i18next";
import PromotionInfoModal from "../../common/PromotionInfoModal";

const CartCoupon = (props)=>{
    const t = props.t;
    const cart = props.cart;
    const [coupon, setCoupon] = useState(cart.coupon);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [promotionInfoModal, setPromotionInfoModal] = useState(undefined);

    const addChangeCoupon =  (event) => {
        setLoading(true)
        props.dispatch(updateOrderCoupon(coupon)).then((newData) => {
            updateErrors([]);
            setErrors([])
            setLoading(false)
        }).catch(error => {
            if(error.status === 422){
                updateErrors(error.errors);
                setErrors(error.errors)
                setLoading(false)
            }else{
                setErrors([])
                setLoading(false)
            }
        });
    }

    const updateErrors = (errors) => {
        let couponErrors = errors.forEach(y => {
            y.field = "coupon"
        });
        props.updateErrors(errors);
    }
    const handleChangeCoupon = (event) => {
        setCoupon(event.target.value)
        props.cart.coupon = event.target.value;
    }
 
    let couponId = cart.coupon_id;
    let couponPromotion = (coupon && !couponId) ? cart.promotions.filter(x => x.metadata && x.metadata.toLocaleUpperCase().includes(`CODE_${coupon.toLocaleUpperCase()}`))[0] : undefined;
    let couponError = (couponPromotion === undefined || !cart.coupon || (cart.discount_money && cart.discount_money.amount >0)) ? false : true;
    return(
        <React.Fragment>
            {promotionInfoModal && (
                <PromotionInfoModal name={promotionInfoModal.name} promotionId={promotionInfoModal.promotion_id} onHide={() => setPromotionInfoModal(undefined)} onShow={() => {}} />
            )}
            <div className={"form-group input-group mb-3" + ErrorFormHelper(props.errors, "coupon") + (couponError ? " is-invalid" : "")}>
                <input type="text" onChange={handleChangeCoupon} value={coupon} className={"form-control" + ErrorFormHelper(props.errors, "coupon") + (couponError ? " is-invalid" : "")} placeholder={t("components.cart.coupon.form.coupon_code_placeholder")} aria-label="Recipient's username" aria-describedby="cart-coupon" />
                <label htmlFor="cart-coupon">{t("components.cart.coupon.form.coupon_code")}</label>
                <button className={"input-group-append btn btn-primary btn-spinner" + (loading ? ' loading' : '')} type="button" id="cart-coupon" disabled={loading} onClick={addChangeCoupon} >
                    <span className="left spinner-border spinner-border-sm"></span>
                    Dodaj
                </button>
                <ErrorFormView errors={props.errors} field="coupon" />
                {couponError && (
                    <>
                        {couponPromotion ? (
                            <div className="invalid-feedback" onClick={() => setPromotionInfoModal(couponPromotion)}>
                                Koszyk nie spełnia warunków promocji: {couponPromotion.name}. <a className={"text-success"}>Dowiedz się więcej</a>
                            </div>
                        ) : (
                            <div className="invalid-feedback">
                                Koszyk nie spełnia warunków promocji
                            </div>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state)=>{
    return {
        cart: getCart(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartCoupon))