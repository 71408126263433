import ItemHelper from "../../../../../application/common/ItemHelper";
import DateService from "../../../../../application/service/DateService";
import ModifierGroupHelper from "../../../../../application/common/ModifierGroupHelper";
import MenuEditItemStateHelper from "./MenuEditItemStateHelper";

class MenuEditItemViewHelper {
    static getItemViewObj = (id, itemState, data) => {
         return MenuEditItemViewHelper.getItemView(data.items, data.modifier_groups, data.availabilities, id, null, null, 1, data.pick_date, itemState, true);
    }
    static getItemView = (items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date, itemState, multiLevel) => {
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        let itemView = {};
        itemView.id = item.id;
        itemView.state = itemState;
        itemView.name = item.translation.name;
        itemView.description = item.translation.description;
        itemView.content = item.translation.content;
        itemView.image_link = item.image_link;
        itemView.disabled = !ItemHelper.isAvailabile(item, availabilities, DateService.emptyOrNow(pick_date));
        itemView.modifier_groups = [];
        itemView.min_permitted = 0;
        itemView.default_quantity = 0;
        itemView.availability_id = item.availability_id;
        itemView.max_permitted = null;
        itemView.charge_above = null;
        itemView.price = ItemHelper.getPriceOvveride(item, parentItem, parentModifierGroup);
        // if(parentItem === undefined || parentItem === null){
        //     parentItem = item;
        // }
        itemView.quantity = 0;
        let itemQuantityInfo = ItemHelper.getItemQuantityInfo(item, parentItem, parentModifierGroup);
        if(itemQuantityInfo !== null){
            itemView.min_permitted = itemQuantityInfo.min_permitted;
            itemView.max_permitted = itemQuantityInfo.max_permitted;
            itemView.charge_above = itemQuantityInfo.charge_above;
            itemView.quantity = itemQuantityInfo.default_quantity !== undefined ? itemQuantityInfo.default_quantity : 0;
            itemView.default_quantity = itemQuantityInfo.default_quantity !== undefined ? itemQuantityInfo.default_quantity : 0;
        }

        itemView.errors = [];
        itemView.isValid = true;
        if(multiLevel){
            if(level > 2){
                return itemView;
            }
        }else{
            if(level > 1){
                return itemView;
            }
        }
        let isCustomize = false;
        if(item.modifier_groups !== undefined){
            item.modifier_groups.forEach(itemModifierGroup => {
                let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
                if(modifierGroup === null || !ModifierGroupHelper.isAvailabile(modifierGroup)){
                    return;
                }
                let modifierGroupView = {
                    id: modifierGroup.id,
                    name: modifierGroup.translation.name,
                    options: [],
                    min_permitted: 0,
                    max_permitted: null,
                    charge_above: null,
                    position: itemModifierGroup.position,
                    quantity: 0,
                    errors:[],
                    isValid: true
                }
                let quantity_info= ItemHelper.getModifierGroupQuantityInfo(modifierGroup, item);
                if(quantity_info !== null){
                    if(quantity_info.max_permitted !== null && quantity_info.max_permitted !== undefined){
                        modifierGroupView.max_permitted = quantity_info.max_permitted;
                    }
                    if(quantity_info.min_permitted !== null && quantity_info.min_permitted !== undefined){
                        modifierGroupView.min_permitted = quantity_info.min_permitted;
                    }
                    if(quantity_info.charge_above !== null && quantity_info.charge_above !== undefined){
                        modifierGroupView.charge_above = quantity_info.charge_above;
                    }
                }
                let stateModifierGroup = itemState ? MenuEditItemStateHelper.getStateModifierGroup(itemState.modifier_groups, modifierGroup.id) : undefined;
                let modifierGroupOnlyOne = modifierGroupView.min_permitted === 1 && modifierGroupView.min_permitted === modifierGroupView.max_permitted && modifierGroup.options.length === 1;
                modifierGroup.options.forEach(modifierGroupItem => {
                    let stateSelectedItem = MenuEditItemStateHelper.getStateModifierGroupSelectedItem(stateModifierGroup, modifierGroupItem.entity_id);
                    let newLevel = level + 1;
                    let newParentItem = item;
                    let modifierGroupItemView = MenuEditItemViewHelper.getItemView(items, modiferGroups, availabilities, modifierGroupItem.entity_id, newParentItem, modifierGroup, newLevel, pick_date, stateSelectedItem, multiLevel);
                    if(modifierGroupItemView === null){
                        return;
                    }
                    let stateSelectedItems = MenuEditItemStateHelper.getStateModifierGroupSelectedItems(stateModifierGroup, modifierGroupItem.entity_id);
                    modifierGroupItemView.states = stateSelectedItems;
                    modifierGroupItemView.position = modifierGroupItem.position;
                    if(modifierGroupItemView !== null){
                        modifierGroupView.options.push(modifierGroupItemView);
                    }
                    let optionQuantity = stateSelectedItems.reduce((sum, item) => sum + item.quantity, 0);
                    let optionQuantityFree = stateSelectedItems.reduce((sum, item) => sum + item.quantity_free, 0);
                    modifierGroupItemView.quantity_free = optionQuantityFree;
                    modifierGroupItemView.quantity = optionQuantity;
                    // modifierGroupItemView.quantity_free = stateSelectedItem ? stateSelectedItem.quantity_free : 0;
                    // modifierGroupItemView.quantity = stateSelectedItem ? stateSelectedItem.quantity : 0;
                    modifierGroupView.quantity = modifierGroupView.quantity + modifierGroupItemView.quantity;
                    modifierGroupItemView.is_change_quantity = true;
                    if((modifierGroupItemView.min_permitted === modifierGroupItemView.max_permitted && modifierGroupItemView.min_permitted === modifierGroupItemView.default_quantity)){
                        modifierGroupItemView.is_change_quantity = false;
                    }
                    if(!isCustomize){
                        if(modifierGroupItemView.modifier_groups.some(x => x.is_customize)){
                          isCustomize = true;
                        }
                        // if(!modifierGroupOnlyOne){
                        //     isCustomize = true;
                        // }
                        if(modifierGroupItemView.is_change_quantity){
                            if(modifierGroupOnlyOne && modifierGroupItemView.default_quantity === 1){
                            }else{
                                isCustomize = true;
                            }
                        }
                    }
                });
                modifierGroupView.is_customize = isCustomize;
                itemView.modifier_groups.push(modifierGroupView);
            });
        }
        itemView.is_customize = isCustomize;
        return itemView;
    }
    static validateStateItem = (itemView, stateItem, availabilities, pick_date, lvl, t) => {
        let isValid = true;
        let allErrors = [];

        itemView.modifier_groups.forEach(itemModifierGroup => {
            let stateItemModifierGroup = stateItem.modifier_groups.filter(x => x.modifier_group_id === itemModifierGroup.id)[0];
            let quantity = stateItemModifierGroup ? stateItemModifierGroup.selected_items.reduce((sum, item) => sum + item.quantity, 0) : 0;
            // let quantity = stateItem ? stateItem.quantity : 0;
            // let quantity = itemModifierGroup.quantity;
            // quantity = itemModifierGroup.quantity;
            if(quantity < itemModifierGroup.min_permitted){

                if(itemModifierGroup.max_permitted !== null && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1){
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_not_empty")});
                    // stateItem.errors.push({"message": t("errors.internal.options_not_empty")});
                    allErrors.push({"message": t("errors.internal.options_not_empty")})
                }else{
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                    // stateItem.errors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                    allErrors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                // stateItem.isValid = false;
                isValid = false;
            }
            if(itemModifierGroup.max_permitted !== null && quantity > itemModifierGroup.max_permitted){
                itemModifierGroup.errors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                // stateItem.errors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                allErrors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                // stateItem.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach(itemModifierGroupItem => {
                let stateItemModifierGroupOption = stateItemModifierGroup ? stateItemModifierGroup.selected_items.filter(x => x.item_id === itemModifierGroupItem.id)[0] : undefined;
                let quantityItem = stateItemModifierGroupOption ? stateItemModifierGroupOption.quantity : 0;

                if(quantityItem > 0 && itemModifierGroupItem.disabled){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                    // stateItem.errors.push({"message": t("errors.internal.item_not_available")});
                    allErrors.push({"message": t("errors.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    // stateItem.isValid = false;
                    isValid = false;
                }
                if(quantityItem < itemModifierGroupItem.min_permitted){

                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    // stateItem.errors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    allErrors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    // stateItem.isValid = false;
                    isValid = false;
                }
                if(itemModifierGroupItem.max_permitted !== null && quantityItem > itemModifierGroupItem.max_permitted){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    // stateItem.errors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    allErrors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    // stateItem.isValid = false;
                    isValid = false;
                }
                let newLvl = lvl ? lvl + 1 : 1;
                if(newLvl <= 2){
                    if(itemModifierGroupItem.quantity > 0){
                        if(!MenuEditItemViewHelper.validateStateItem(itemModifierGroupItem, stateItemModifierGroupOption, undefined, undefined, newLvl, t)){
                            isValid = false;
                        }
                    }
                }
            });
        });
        // if(allErrors.length > 0){
        //     console.log("allErrors", allErrors, itemView);
        // }
        itemView.all_errors = allErrors;
        return isValid;
    }
    static validateState = (itemView, availabilities, pick_date, lvl, t) => {
        let isValid = true;
        let allErrors = [];

        itemView.modifier_groups.forEach(itemModifierGroup => {
            let quantity = itemModifierGroup.quantity;
            if(quantity < itemModifierGroup.min_permitted){

                if(itemModifierGroup.max_permitted !== null && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1){
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_not_empty")});
                    allErrors.push({"message": t("errors.internal.options_not_empty")})
                }else{
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                    allErrors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            if(itemModifierGroup.max_permitted !== null && quantity > itemModifierGroup.max_permitted){
                itemModifierGroup.errors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                allErrors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach(itemModifierGroupItem => {
                let quantityItem = itemModifierGroupItem.quantity;

                if(quantityItem > 0 && itemModifierGroupItem.disabled){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                    allErrors.push({"message": t("errors.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if(quantityItem < itemModifierGroupItem.min_permitted){

                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    allErrors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if(itemModifierGroupItem.max_permitted !== null && quantityItem > itemModifierGroupItem.max_permitted){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    allErrors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                let newLvl = lvl ? lvl + 1 : 1;
                if(newLvl <= 2){
                    if(itemModifierGroupItem.quantity > 0){
                        if(!MenuEditItemViewHelper.validateState(itemModifierGroupItem, undefined, undefined, newLvl, t)){
                            isValid = false;
                        }
                    }
                }
            });
        });
        // if(allErrors.length > 0){
        //     console.log("allErrors", allErrors, itemView);
        // }
        itemView.all_errors = allErrors;
        return isValid;
    }
    static getErrors = (item) => {
        let errors = [];
        item.modifier_groups.forEach(itemModifierGroup => {
            if (itemModifierGroup.errors.length > 0) {
                itemModifierGroup.errors.forEach(error => {
                    errors.push({
                        message: error.message,
                        resource: itemModifierGroup.name
                    })
                })
            }
        });
        return errors;
    }
}
export default MenuEditItemViewHelper;