import CookiesStore from "./cookiesStore";

class UserContext {
    static token = null;
    static initialize(){

    }
    static setToken(token){
        this.token = token;
        if(token !== undefined && token){
            CookiesStore.set("utk", token);
        }else{
            CookiesStore.remove("utk");
        }
    }
    static getToken(){
        if(this.token){
            return this.token;
        }
        const token = CookiesStore.get("utk");
        if(token) return token;
        return null;
    }
}
export default UserContext;
