import React, {useState} from 'react';
import {connect} from "react-redux";
import Money from "../../../../../common/Money";
import {withTranslation} from "react-i18next";
import ItemHelper from "../../../../../../application/common/ItemHelper";
import {
    getAvailabilities,
    getItems,
    getModifierGroups,
    isItemMultiLevel
} from "../../../../../../application/menu/reducer";
import {ReactComponent as CheckCircleSVG} from "../../../../../../images/icons/check-circle.svg";
import StateItemHelper from "../../MenuShowItem/StateItemHelper";
import PromotionHelper from "../../../../../../application/common/PromotionHelper";
import MenuEditItemViewHelper from "../../MenuShowItem/MenuEditItemViewHelper";
import {getPickDate} from "../../../../../../application/cart/reducer";
import {Modal} from "react-bootstrap";
import MenuShowItemSingleV3 from "../MenuShowItemSingleV3";
import {ReactComponent as LeftIco} from "../../../../../../images/icons/arrow-left.svg";
import MenuItemOptionWithImage from "./MenuItemOptionWithImage";
import MenuItemBaseModal from "./MenuItemBaseModal";

const MenuModifierGroupsPickOneV2 = (props) => {
    const {t, modifierGroupId, id} = props;
    const [stateItem, setStateItem] = useState(props.stateItem ? JSON.parse(JSON.stringify(props.stateItem)) : undefined);
    const [page, setPage] = useState(undefined);
    let itemId = stateItem.item_id;
    let data = {
        items: props.items,
        modifier_groups: props.modifier_groups,
        availabilities: props.availabilities,
        pick_date: props.pick_date
    };
    let itemView = MenuEditItemViewHelper.getItemViewObj(itemId, stateItem, data);

    let modifierGroupState = stateItem.modifier_groups.filter(x => x.modifier_group_id == modifierGroupId)[0];
    let selectedItemState = modifierGroupState ? modifierGroupState.selected_items[id - 1] : undefined;
    let modifierGroupView = itemView.modifier_groups.filter(x => x.id == modifierGroupId)[0];
    let isValid = false;
    if(selectedItemState){
        let itemViewOption = modifierGroupView.options.filter(x => x.id === selectedItemState.item_id)[0];
        isValid = MenuEditItemViewHelper.validateStateItem(itemViewOption, selectedItemState, props.availabilities, props.pick_date, undefined, t);
        // isValid = MenuEditItemViewHelper.validateState(itemViewOption, props.availabilities, props.pick_date, undefined, t);
    }
    const setQuantityItem = (modifierGroupId, itemView, quantity) => {
        StateItemHelper.setItemOption(stateItem, modifierGroupId, itemView.id, quantity, props.items, props.modifier_groups, id);
        updateState(stateItem);
    }
    const updateState = (newItem) => {
        StateItemHelper.refreshItemPrice(newItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
        let copyItem = Object.assign({}, newItem);
        setStateItem(copyItem);
    }

    const save = () => {
        props.onUpdate(stateItem);
    }
    const renderInput = (item, modifierGroup, optionItem) => {
        let optionStateItem = selectedItemState && selectedItemState.item_id === item.id ? selectedItemState : undefined;
        return (
            <MenuItemOptionWithImage singlePick={true} stateItem={optionStateItem} modifierGroupId={modifierGroup.id} optionItemView={optionItem} onUpdate={setQuantityItem} onEdit={editSubLevel} />
        );
    }
    const isModal = () => {
        return true;
    }
    const editSubLevel = (stateSubItem) => {
        let subItem  = ItemHelper.getItem(props.items, stateSubItem.item_id)
        setPage({
            image: subItem.image_link ? subItem.image_link.default : undefined,
            render: () => {
                const updateSubItem = (newStateItem) => {
                    let modifierGroupId = newStateItem.parent_modifier_group_id;
                    let copyItem = Object.assign({}, stateItem);
                    copyItem.modifier_groups.forEach(mg => {
                        if (mg.modifier_group_id !== modifierGroupId) return;
                        mg.selected_items[id - 1] = newStateItem;
                    });
                    // StateItemHelper.refreshItemPrice(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart);
                    StateItemHelper.refreshItemPriceWithParent(copyItem, PromotionHelper.getForItem(props.promotions, stateItem.item_id), props.cart, modifierGroupId, props.items, props.modifier_groups);
                    // StateItemHelper.refreshStateModifierGroupItemOption(copyItem, modifierGroupId, props.items, props.modifier_groups);
                    setStateItem(copyItem);
                    setPage(undefined);
                }
                return (
                    <>
                        <MenuShowItemSingleV3 parent={stateItem} onHide={() => setPage(undefined)}
                                              errors={props.errors} itemId={stateSubItem.item_id} menu={props.menu}
                                              onUpdate={updateSubItem} stateItem={stateSubItem}/>
                    </>
                )
            }
        })
    }
    if (modifierGroupView.max_permitted !== null && modifierGroupView.max_permitted <= 0) {
        return null;
    }
    if (page) {
        if(isModal()){
            return (
                <MenuItemBaseModal image={page.image}>
                    <>
                        {page.render()}
                    </>
                </MenuItemBaseModal>
            )
        }
        return (
            <>
                {page.render()}
            </>
        )
    }
    let disabled = !selectedItemState || !isValid;
    let loading = false;
    return (
        <>
            <Modal.Header className={"modal-header-back"}>
                <a className={"back-icon text-primary"} href={"#"} onClick={() => props.onChangePage(undefined)}>
                    <LeftIco className="icon"/>
                </a>
                <Modal.Title>
                    Wybierz {modifierGroupView.name}
                    {/*{props.itemGroup ? props.itemGroup.translation ? props.itemGroup.translation.name : itemGroup.name : props.item.translation.name} > Wybierz {modifierGroup.name}*/}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"product-item"}>

                    <div className={"product-item-content"}>
                        <fieldset className="form-group">
                            <legend>
                                {modifierGroupView.name}
                            </legend>
                            <div>
                                {
                                    modifierGroupView.options.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((optionItem) => {
                                        let item = ItemHelper.getItem(props.items, optionItem.id);
                                        return (
                                            <React.Fragment key={modifierGroupView.id + "-" + optionItem.id}>
                                                {renderInput(item, modifierGroupView, optionItem)}
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </fieldset>
                    </div>

                    <div className="product-item-footer row">
                        <div className="btn-group-submit">
                            <button
                                className={"btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom" + (loading ? ' loading' : '')}
                                variant="primary" disabled={disabled} onClick={save}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                {t("common.action.add")}
                                <div className="btn-cart-price">
                                    <span className="price">
                                        {selectedItemState && (
                                            <Money
                                                value={selectedItemState.total_price}
                                                currency='PLN'/>
                                        )}
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu),
        availabilities: getAvailabilities(state.menu),
        pick_date: getPickDate(state.cart),
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuModifierGroupsPickOneV2))