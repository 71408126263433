import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser, getVouchers} from "../../../../application/user/reducer";
import {Link} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import {Button} from "react-bootstrap";
import VoucherModal from "./components/VoucherModal";
import UserService from "../../../../application/user/userService";
import LoadingContainer from "../../../common/LoadingContainer";
import {ReactComponent as VoucherSVG} from "../../../../images/icons/voucher.svg";
import Utils from "../../../utils/Utils";
import VoucherProductModal from "./components/VoucherProductModal";

const UserVouchersPage = (props) => {
    const [voucher, setVoucher] = useState(undefined);
    const [product, setProduct] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    let activeVouchers = props.vouchers;

    useEffect(() => {
        getProducts();
    }, []);
    const isAvailableVoucher = (voucher) => {
        return true;
        // return !props.orderPromotions.some(orderPromotion => voucher.promotion_id == orderPromotion.promotion_id && orderPromotion.reference_id == voucher.id);
        // return true;
    }
    const showVoucher = (voucher) => {
        setProduct(undefined)
        setVoucher(voucher)
    }
    const showProduct = (product) => {
        setVoucher(undefined)
        setProduct(product)
    }

    const getProducts = async () => {
        let data = await UserService.getProducts("GOCRM");
        setProducts(data);
        setLoading(false)
        // return data;
    }
    const buyVoucher = (voucher) => {
        console.log(voucher.id, activeVouchers);
        console.log(voucher, activeVouchers);
        showVoucher(voucher);
    }
    const renderPromotion = (voucher) => {
        const product = voucher.product;
        return (
            <div
                onClick={(e) => showVoucher(voucher)}
                className={"voucher-card card flex-column flex-fill " + (!isAvailableVoucher(voucher) ? ' disabled' : '')}>
                <div className={"card-img"}>
                    {product && product.image ? (
                        <img src={product.image}
                             className="card-img-top" alt="..."/>
                    ) : (
                        <div className={"card-img-color"} style={product ? {backgroundColor: product.color} : ""}>
                            <VoucherSVG/></div>
                    )}
                </div>
                <div className="card-body">
                    <h5 className="card-title">{product ? product.name : voucher.name}</h5>
                    <p className="card-text"
                       dangerouslySetInnerHTML={{__html: Utils.nl2brString(product ? product.description : voucher.description)}}></p>
                </div>
                <div className="card-footer mt-auto">
                    <Button className="btn btn-primary w-100">Odbierz</Button>
                </div>
                {!isAvailableVoucher(voucher) && (
                    <span className={"badge bg-primary"}>Niedostępny</span>
                )}
            </div>
        )
    }

    const renderProduct = (product) => {
        return (
            <div
                className={"voucher-card card flex-column flex-fill " + (!isAvailableVoucher(product) ? ' disabled' : '')}
                onClick={(e) => showProduct(product)}>
                <div className={"card-img"}>
                    {product.image ? (
                        <img src={product.image}
                             className="card-img-top" alt="..."/>
                    ) : (
                        <div className={"card-img-color"} style={{backgroundColor: product.color}}><VoucherSVG/></div>
                    )}
                    {product.cost ? (
                        <span className={"points"}>{product.cost} pkt</span>
                    ) : (
                        <span className={"points"}>Darmowy</span>
                    )}
                    {/*<span className={"valid-time"}>Ważny do: 12.01.25</span>*/}
                </div>
                <div className="card-body">
                    <h5 className="card-title">{product.name}</h5>
                    <p className="card-text">{product.description}</p>
                    {/*<Button className={"btn btn-primary"}>skorzystaj</Button>*/}
                </div>
                <div className="card-footer mt-auto">
                    <Button className="btn btn-primary w-100">Odbierz za {product.cost ? `${product.cost} pkt` : "darmo"}</Button>
                </div>
            </div>
        )
    }
    return (

        <>
            {voucher && (
                <>
                    <VoucherModal onHide={() => setVoucher(undefined)} show={true} voucher={voucher}/>
                </>
            )}
            {product && (
                <>
                    <VoucherProductModal onSuccess={buyVoucher} onHide={() => setProduct(undefined)} show={true} product={product}/>
                </>
            )}
            <div className={"user"}>
                <div className={"user-header-top top"}>
                    <>
                        <Link to={"/user"} className={"btn-back"}>
                            <BackSVG/>
                        </Link>
                        <h5 className="">
                            Vouchery
                        </h5>
                    </>
                </div>
                <div className={"container mt-2 vouchers"}>
                    {activeVouchers.length > 0 ? (
                        <section className="vouchers">
                            <h6>
                                Moje vouchery
                            </h6>
                            <div className="row row-cols-2 row-cols-lg-3 g-2">
                                {
                                    activeVouchers.sort((a, b) => a.name.localeCompare(b.name)).map(voucher => {
                                            return (
                                                <React.Fragment key={voucher.id}>
                                                    <div className={"col d-flex"}>
                                                        {renderPromotion(voucher)}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </section>
                    ) : (
                        <div>
                        Nie posiadasz żadnych voucherów.
                        </div>
                    )}
                    {loading ? (
                        <>
                            <LoadingContainer>Trwa pobieranie Vouhcerów</LoadingContainer>
                        </>
                    ) : (
                        <>
                            <section className="vouchers">
                                <h6>
                                    Vouchery do kupienia
                                </h6>
                                <div className="row row-cols-2 row-cols-lg-3 g-2">
                                    {
                                        products.sort((a, b) => a.name.localeCompare(b.name)).map(product => {
                                                return (
                                                    <React.Fragment key={product.id}>
                                                        <div className={"col d-flex"}>
                                                            {renderProduct(product)}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        )
                                    }
                                </div>
                            </section>

                        </>
                    )}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user),
    vouchers: getVouchers(state.user),
})
export default withTranslation()(connect(mapStateToProps)(UserVouchersPage))
