import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLocale} from "../../../../../application/store/reducer";
import {getCart} from "../../../../../application/cart/reducer";
import {getLocation} from "../../../../../application/location/reducer";
import ErrorFormAlertView from "../../../../common/ErrorFormAlertView";
import {ReactComponent as VoucherSVG} from "../../../../../images/icons/voucher.svg";
import UserService from "../../../../../application/user/userService";

function VoucherProductModal(props) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const product = props.product;
    const buy = async (product) => {
        setLoading(true);
        try {
            let response = await UserService.buyVoucher(product.provider, product.id);
            // let data = await UserService.buyVoucher(product.provider, product.id);
            let vouchers = await props.dispatch(UserService.syncVouchers());
            if (vouchers && response) {
                let userVoucher = vouchers.filter(x => x.id === response.id)[0];
                console.log(response, vouchers, userVoucher);
                props.onSuccess(userVoucher);
            } else {
                props.onSuccess(undefined);
            }
            setLoading(false);
        } catch (e) {
            if (e.status === 403 || e.status === 404) {
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            } else if (e.status == 422) {
                console.log(e);
                setErrors([{
                    message: "Nie udało się kupić vouchera."
                }]);
            } else {
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Nie udało się zalogować"
                    }
                ])
            }
            setLoading(false);
        }

    }
    const onShow = () => {
    }


    return (
        <>
            <Modal dialogClassName="modal-sm modal-login" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"voucher-full"}>

                        <div className={"voucher-full-img"}>
                            {product.image ? (
                                <img src={product.image}/>
                            ) : (
                                <div className={"voucher-full-img-color"} style={{backgroundColor: product.color}}>
                                    <VoucherSVG/>
                                </div>
                            )}
                            {product.cost ? (
                                <span className={"points"}>{product.cost} pkt</span>
                            ) : (
                                <span className={"points"}>Darmowy</span>
                            )}
                            {/*<span className={"valid-time"}>Ważny do: 12.01.25</span>*/}
                        </div>
                        <h5>
                            {product.name}
                        </h5>
                        <p className="card-text">{product.description}</p>
                        <ErrorFormAlertView errors={errors}/>

                        <Button
                            className={`btn btn-spinner btn-primary w-100 ${loading ? " loading disabled" : ""}`}
                            onClick={() => buy(product)}>
                            <span className="left spinner-border spinner-border-sm"></span>
                            Odbierz voucher za {product.cost ? `${product.cost} pkt` : "darmo"}
                        </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: getCart(state.cart),
        location: getLocation(state.location),
        locale: getLocale(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(VoucherProductModal))
