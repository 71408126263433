import React, {useState} from 'react';
import {connect} from "react-redux";
import {updateItem} from "../../../application/cart/cartService";
import {getCart, getItems, getOrderPending} from "../../../application/cart/reducer";
import Money from "../../common/Money";
import {withTranslation} from "react-i18next";
import OrderHelper from "../../../application/common/OrderHelper";
import {getModifierGroups, getPromotions} from "../../../application/menu/reducer";
import PromotionHelper from "../../../application/common/PromotionHelper";
import {ReactComponent as PlusCircleIco} from "../../../images/icons/plus-circle.svg";
import CartVouchers from "./CartVouchers";
import CartPromotions from "./CartPromotions";
import CartUserVouchers from "./CartUserVouchers";
import Analytics from "../../../application/common/Analytics";
import AppConfig from "../../../application/common/AppConfig";
import MenuEditItem from "../base/menu/MenuEditItem";

const CartItems = (props) => {
    const {t, orderItems, items, modifierGroups, cart, promotions} = props;
    const visibileModifierGroupName = false;
    const [modalEditItem, setModalEditItem] = useState(undefined);


    let cartPromotions = cart.promotions;
    let suggestPromotions = PromotionHelper.getSuggest(promotions, cart);

    const changeQuantity = (id, value) => {
        props.dispatch(updateItem(id, value)).then(newData => {
        });
        if (Number(value) === 0) {
            let orderItemToRemove = orderItems.filter(x => x.id == id)[0];
            if (orderItemToRemove) {
                Analytics.removeFromCart(orderItemToRemove);
            }
        }
    }
    const handleChange = (id, e) => {
        var value = e.target.value;
        changeQuantity(id, value);
    }
    const addPromotion = (promotionId, orderItemId) => {
        let orderItem = props.cart.items.filter(orderItem => orderItem.id == orderItemId)[0];
        if (orderItem === undefined) return;
        props.dispatch(updateItem(orderItem.id, orderItem.quantity + 1)).then(newData => {
        });
    }
    const buildOptions = () => {
        var arr = [];

        for (let i = 1; i <= 20; i++) {
            arr.push(<option key={i} value={i}>{i}</option>)
        }

        return arr;
    }
    const renderItemPrice = (item) => {
        if (item.discount_money !== undefined && item.discount_money !== null && item.discount_money.amount !== 0) {
            let subTotalPrice = item.total_money.amount + item.discount_money.amount;
            return (
                <React.Fragment>
                    <span className="text-danger">
                        <del>
                        <Money
                            value={subTotalPrice}
                            currency='PLN'/>
                        </del>
                    </span>
                    <Money
                        value={item.total_money.amount}
                        currency='PLN'/>
                </React.Fragment>
            )
        }
        return (
            <Money
                value={item.total_money.amount}
                style="currency"
                currency='PLN'/>
        )
    }
    const renderPromotionItem = (item, promotions) => {
        let valuePromotions = promotions.filter(x => x.value_money && x.value_money.amount);
        let promotion = valuePromotions.filter(x => x.items.some(y => y.order_item_id == item.id))[0];
        if (promotion === undefined) return null;
        return (
            <small>
                <br/>
                {promotion.name}
            </small>
        )
    }
    const getPromotionItem = (item, promotions) => {
        let valuePromotions = promotions.filter(x => x.value_money && x.value_money.amount);
        let promotion = valuePromotions.filter(x => x.items.some(y => y.order_item_id == item.id))[0];
        if (promotion === undefined) return null;
        return promotion;
    }
    const renderSubItems = (itemModifierGroups, modifierGroups, items, lvl) => {
        if (!itemModifierGroups || itemModifierGroups.length <= 0) return null;
        if (!lvl) lvl = 0;
        if (visibileModifierGroupName) {
            return (
                <>
                    {
                        itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).map((itemModifierGroup) => {
                                return (
                                    <ul className="list-inline" key={itemModifierGroup.id}>
                                        <h5>
                                            {OrderHelper.getItemName(modifierGroups, itemModifierGroup.modifier_group_id, itemModifierGroup.name)}
                                        </h5>
                                        {
                                            itemModifierGroup.selected_items.sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>
                                                <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`}
                                                    key={selectedItem.id}>
                                                    {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                                    {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                                </li>
                                            )
                                        }
                                    </ul>
                                )
                            }
                        )
                    }
                </>
            )
        }
        return (
            <ul className="list-inline">
                {
                    itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).flatMap(x => x.selected_items).sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>
                        <li className={`${isModifiers(selectedItem) ? "list-inline-item list-inline-item-multi" : "list-inline-item"}`}
                            key={selectedItem.id}>
                            {isModifiers(selectedItem) ? (
                                <h5>
                                    {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                </h5>
                            ) : (
                                <>
                                    {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)} {selectedItem.quantity > 1 ? `(x${selectedItem.quantity})` : ''}
                                </>
                            )}
                            {renderSubItems(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                        </li>
                    )
                }
            </ul>
        )
    }
    const renderSubItemsString = (itemModifierGroups, modifierGroups, items, lvl) => {
        if (!itemModifierGroups || itemModifierGroups.length <= 0) return null;
        if (!lvl) lvl = 0;
        return (
            <>
                {AppConfig.isDesignV3() ? (
                    <>
                        {
                            itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).map(x => {
                                let itemsCount = x.selected_items.length;
                                let newLine = false;
                                return (
                                    <React.Fragment key={x.id}>
                                        <strong>{x.name}: </strong>
                                        {x.selected_items.sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem, key) => {
                                            let existModifiers = isModifiers(selectedItem);
                                            newLine = !existModifiers;
                                            return (
                                                <React.Fragment
                                                    key={selectedItem.id}>
                                                    {existModifiers ? (
                                                        <div style={{paddingLeft: 15*lvl}}>
                                                            <strong>
                                                                {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}: {` `}
                                                            </strong>
                                                            <div style={{paddingLeft: 5*(lvl+1)}}>
                                                                {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}{itemsCount-1 > key && (<>, </>)}
                                                            {/*, {` `}*/}
                                                            {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                        {newLine && <br />}
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                ) : (
                    <>
                        {
                            itemModifierGroups.sort((a, b) => a.name.localeCompare(b.name)).flatMap(x => x.selected_items).sort((a, b) => a.name.localeCompare(b.name)).map((selectedItem) =>
                                <React.Fragment
                                    key={selectedItem.id}>
                                    {isModifiers(selectedItem) ? (
                                        <div>
                                            <strong>
                                                {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}: {` `}
                                            </strong>
                                            {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                        </div>
                                    ) : (
                                        <>
                                            {OrderHelper.getItemName(items, selectedItem.item_id, selectedItem.name)}{selectedItem.quantity > 1 ? ` (x${selectedItem.quantity})` : ''}, {` `}
                                            {renderSubItemsString(selectedItem.modifier_groups, modifierGroups, items, lvl + 1)}
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        }
                    </>
                )}

            </>
        )
    }
    const isModifiers = (item) => {
        return item.modifier_groups && item.modifier_groups.length > 0;
    }
    return (
        <div>
            {modalEditItem && (
                <MenuEditItem orderItem={modalEditItem} onHide={() => setModalEditItem(undefined)} />
            )}
            {AppConfig.isDesignV3() ? (
                <>
                    {orderItems.length > 0 ? (
                        <div className="cart-items-body">
                            {
                                orderItems.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((orderItem) => {
                                        let item = OrderHelper.getItem(items, orderItem.item_id)
                                        let promotionItem = getPromotionItem(orderItem, cartPromotions);
                                        return (
                                            <div key={orderItem.id} className={"cart-item"}>
                                                {item.image_link?.small && (
                                                    <div className={"cart-item-img"} onClick={() => setModalEditItem(orderItem)}>
                                                        <img src={item.image_link.small} alt={item.name}/>
                                                    </div>
                                                )}
                                                <div className={"cart-item-body"}>
                                                    <h3 onClick={() => setModalEditItem(orderItem)}>
                                                        {OrderHelper.getItemName(items, orderItem.item_id, orderItem.name)}
                                                    </h3>
                                                    <div className={"cart-item-description"} onClick={() => setModalEditItem(orderItem)}>
                                                        {(isModifiers(orderItem)) && (
                                                            <>
                                                                {renderSubItemsString(orderItem.modifier_groups, modifierGroups, items)}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className={"cart-item-bottom"}>
                                                        <div>
                                                            <div
                                                                className="input-group input-group-sm input-group-quantity">
                                                                <div className="input-group-prepend">
                                                                    <button type="button"
                                                                            className="btn btn-secondary"
                                                                            onClick={() => changeQuantity(orderItem.id, orderItem.quantity - 1)}>-
                                                                    </button>
                                                                </div>
                                                                <span
                                                                    className="input-group-text">{orderItem.quantity}</span>
                                                                <div className="input-group-append">
                                                                    <button type="button"
                                                                            className="btn btn-secondary"
                                                                            onClick={() => changeQuantity(orderItem.id, orderItem.quantity + 1)}>+
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"cart-item-prices"}>
                                                            {renderItemPrice(orderItem)}
                                                        </div>
                                                    </div>
                                                    {promotionItem && (
                                                        <div className={"cart-item-promotion"}>
                                                            {promotionItem.name}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    ) : (
                        <p className={"text-center"}>
                            {t("components.cart.basket.not_items")}
                        </p>
                    )}
                </>
            ) : (
                <>

                    {orderItems.length > 0 ? (
                        <table className="table table-items">
                            <tbody>
                            {
                                orderItems.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((orderItem) => {
                                        return (
                                            <React.Fragment key={orderItem.id}>
                                                <tr className={`${isModifiers(orderItem) ? "item-line-with-modifiers" : "item-line-single"}`}>
                                                    <td className="cart-items-quantity">
                                                        <select className="form-select form-select-sm"
                                                                value={orderItem.quantity}
                                                                onChange={(e) => handleChange(orderItem.id, e)}>
                                                            <option value="0">{t("common.action.remove")}</option>
                                                            {buildOptions()}
                                                        </select>
                                                    </td>
                                                    <td className="cart-items-name">
                                                        <h4>
                                                            {OrderHelper.getItemName(items, orderItem.item_id, orderItem.name)}
                                                        </h4>
                                                    </td>
                                                    <td className="text-end">
                                                        {renderItemPrice(orderItem)}
                                                        {renderPromotionItem(orderItem, cartPromotions)}
                                                    </td>
                                                </tr>
                                                {(isModifiers(orderItem)) && (
                                                    <tr className={"item-modifiers-line"}>
                                                        <td></td>
                                                        <td colSpan={2} className="cart-items-name">
                                                            <div>
                                                                {renderSubItems(orderItem.modifier_groups, modifierGroups, items)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    ) : (
                        <p className={"text-center"}>
                            {t("components.cart.basket.not_items")}
                        </p>
                    )}
                </>
            )}

            <CartVouchers/>
            <CartPromotions/>
            <CartUserVouchers/>
            <div className={"container cart-promotions"}>
                {suggestPromotions.map(promotion => {
                    return (
                        <button key={promotion.id} className={"btn btn-promotion btn-spinner" + (props.pending ? ' loading' : '')}
                                onClick={() => addPromotion(promotion.id, promotion.info.order_item_id)}>
                            <div className={"content"}>
                                {promotion.item_name}
                                <small>
                                    {promotion.name}
                                </small>
                            </div>
                            <div className={"icon"}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                <PlusCircleIco/>
                            </div>
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        orderItems: getItems(state.cart),
        cart: getCart(state.cart),
        promotions: getPromotions(state.menu),
        items: getItems(state.menu),
        modifierGroups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartItems))