import React, {useState} from 'react';
import ErrorFormHelper from "../../ErrorFormHelper";
import ErrorFormView from "../../ErrorFormView";
import ErrorFormAlertView from "../../ErrorFormAlertView";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getUser} from "../../../../application/user/reducer";
import {getStore} from "../../../../application/store/reducer";
import UserService from "../../../../application/user/userService";
import {Link} from "react-router-dom";
import {getAddressName} from "../../../../application/common/StringHelper";
import {getOrganization} from "../../../../application/menu/reducer";

function RegisterUserComponent(props) {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [acceptRules, setAcceptRules] = useState(false);
    const [acceptMarketingRules, setAcceptMarketingRules] = useState(false);
    const [cardCode, setCardCode] = useState(undefined);


    const getRules = () => {
        const {store} = props;
        let stateAcceptRules = [];
        store.links.filter(x => x.type === "RULES").sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map(storeLink => {
            let stateAcceptRule = {
                id: storeLink.id,
                link: storeLink.link,
                name: storeLink.name,
                accepted: false
            }
            stateAcceptRules.push(stateAcceptRule);
        })
        return stateAcceptRules;
    }

    let renderMarketingContent = () => {
        let organization = props.organization;
        let companyName = null;
        let addressView = null;
        if (organization !== null) {
            companyName = organization.company_name;
            if (organization.address !== null) {
                addressView = getAddressName(organization.address);
            }
        }
        if (props.store.settings && props.store.settings.rules_marketing_text) {
            if (props.store.settings.rules_marketing_text.includes("VARIANT_2")) {
                let additionalInfos = props.store.settings.rules_marketing_text.split("|");
                if (additionalInfos.length > 1 && additionalInfos[1]) {
                    companyName = additionalInfos[1];
                }
                if (additionalInfos.length > 2 && additionalInfos[2]) {
                    addressView = additionalInfos[2];
                }
                return (
                    <>
                        Wyrażam zgodę na przesyłanie informacji handlowych przez {companyName} z siedzibą
                        w {addressView} za pośrednictwem komunikacji elektronicznej (SMS, e-mail). Wyrażam zgodę na
                        przetwarzanie moich danych osobowych w celu przesyłania informacji handlowych przez T-Pizza sp.
                        z o.o. Podstawą prawną przetwarzania danych osobowych jest wyrażona przeze mnie zgoda. Rozumiem,
                        że przysługuje mi prawo do cofnięcia zgody w dowolnym momencie, bez wpływu na zgodność z prawem
                        przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
                    </>
                )
            }
        }

        return (
            <>
                {(props.store.settings && props.store.settings.rules_marketing_text) ? props.store.settings.rules_marketing_text : `Wyrażam zgodę na wykorzystanie moich danych osobowych przez ${companyName} z siedzibą w ${addressView} do celów marketingowo-reklamowych zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady EU z dnia 27 kwietnia 2016 r. „o ochronie osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE” zwanym dalej „RODO” oraz na otrzymywanie na powyższy adres e-mail oraz numer telefonu informacji handlowych drogą elektroniczną.`}
            </>
        )
    }


    let rules = getRules()[0];
    let marketingRules = undefined;
    const register = async () => {
        setLoading(true);
        try {
            let response = await props.dispatch(UserService.register(email, name, phone, password, confirmPassword, acceptRules, acceptMarketingRules, cardCode))
            setLoading(false);
            props.onSuccess()
        } catch (e) {
            console.log(e);
            if (e.status === 403 || e.status === 404) {
                setErrors([
                    {
                        "code": "wrong_registration",
                        "message": "Niepoprawne dane do rejestracji"
                    }
                ])
            } else if (e.status == 422) {
                setErrors(e.errors);
            } else {
                setErrors([
                    {
                        "code": "wrong_registration",
                        "message": "Nie udało się zarejestrować"
                    }
                ])
            }
            setLoading(false);
        }

    }
    const renderRuleView = (storeLink) => {
        if (storeLink.link == 'rules') {
            return (
                <Link to={storeLink.link} target="_blank">
                    Link
                </Link>
            )
        }

        let links = [];
        let storeLinks = storeLink.link.split("|");
        if (storeLinks.length > 1) {
            storeLinks.forEach(storeLink => {
                let storeLinkSplit = storeLink.split("::");
                if (storeLinkSplit.length > 1) {
                    links.push({
                        "title": storeLinkSplit[0],
                        "link": storeLinkSplit[1]
                    })
                } else {
                    links.push({
                        "title": "Link",
                        "link": storeLinkSplit[0]
                    })
                }
            });
        } else {
            links.push({
                "title": "Link",
                "link": storeLink.link
            })
        }
        return (
            <>
                {links.map((link, index) => {
                    return (
                        <>
                            <a href={link.link} target="_blank">{link.title}</a>{index < links.length - 1 && ", "}
                        </>
                    )
                })}
            </>
        )
    }
    const renderTitleRules = (item) => {
        return (
            <>
                {item.name} ({renderRuleView(item)})
            </>
        );
    }
    return (
        <>
            <div>
                <h4>
                    Załóż konto
                </h4>
                <ErrorFormAlertView show_message={true} errors={errors}/>
                <div className="form-group">
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name}
                           className={"form-control" + ErrorFormHelper(errors, "name")} placeholder="Imię i nazwisko"
                           aria-label="Recipient's username" aria-describedby="cart-name"/>
                    <label htmlFor="cart-name">Imię i nazwisko</label>
                    <ErrorFormView errors={errors} show_message={true} field="name"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone}
                           className={"form-control" + ErrorFormHelper(errors, "phone")} placeholder="Numer telefonu"
                           aria-label="Recipient's username" aria-describedby="cart-phone"/>
                    <label htmlFor="cart-phone">Numer telefonu</label>
                    <ErrorFormView errors={errors} show_message={true} field="phone"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email}
                           className={"form-control" + ErrorFormHelper(errors, "email")} placeholder="E-mail"
                           aria-label="Recipient's username" aria-describedby="cart-username"/>
                    <label htmlFor="cart-username">E-mail</label>
                    <ErrorFormView errors={errors} show_message={true} field="username"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                           className={"form-control" + ErrorFormHelper(errors, "password")} placeholder="Hasło"
                           aria-label="Recipient's username" aria-describedby="cart-password"/>
                    <label htmlFor="cart-password">Hasło</label>
                    <ErrorFormView errors={errors} show_message={true} field="password"/>
                </div>
                <div className="form-group">
                    <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}
                           className={"form-control" + ErrorFormHelper(errors, "confirm_password")}
                           placeholder="Potwierdz hasło" aria-label="Recipient's username"
                           aria-describedby="cart-confirm-password"/>
                    <label htmlFor="cart-confirm-password">Potwierdz hasło</label>
                    <ErrorFormView errors={errors} show_message={true} field="confirm_password"/>
                </div>
                <div className="form-group">
                    <input type="text" onChange={(e) => setCardCode(e.target.value)} value={cardCode}
                           className={"form-control" + ErrorFormHelper(errors, "cardCode")} placeholder="Kod promocyjny"
                           aria-label="Recipient's username" aria-describedby="cart-name"/>
                    <label htmlFor="cart-cardCode">Kod promocyjny</label>
                    <ErrorFormView errors={errors} show_message={true} field="name"/>
                </div>
                {rules && (
                    <div className="form-group">
                        <div className={"form-check"}>
                            <input id="cart-accept_rules" name={"accept_rules"} type="checkbox"
                                   onChange={(e) => setAcceptRules(!acceptRules)} value={1} checked={acceptRules}
                                   className={"form-check-input" + ErrorFormHelper(errors, "accept_rules")}
                                   aria-label="Akceptacja regulaminu" aria-describedby="cart-accept_rules"/>
                            <label className={"form-check-label"}
                                   htmlFor="cart-accept_rules">{renderTitleRules(rules)}</label>
                            <ErrorFormView errors={errors} show_message={true} field="accept_rules"/>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <div className={"form-check"}>
                        <input id="cart-accept_marketing_rules" name="accept_marketing_rules" type="checkbox"
                               onChange={(e) => setAcceptMarketingRules(!acceptMarketingRules)} value={1}
                               checked={acceptMarketingRules}
                               className={"form-check-input" + ErrorFormHelper(errors, "accept_marketing_rules")}
                               aria-label="Akceptacja regulaminu marketingowego"
                               aria-describedby="cart-accept_marketing_rules"/>
                        <label className={"form-check-label"}
                               htmlFor="cart-accept_marketing_rules">{renderMarketingContent()}</label>
                        <ErrorFormView errors={errors} field="accept_marketing_rules"/>
                    </div>
                </div>
            </div>
            <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')}
                    onClick={() => {
                        register()
                    }}>
                <span className="left spinner-border spinner-border-sm"></span>
                Założ konto
            </button>

            <div className={"login-box-register-info"}>
                <hr/>
                <div className={"text-center"}>
                    Masz już konto? <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    props.setPage("LOGIN")
                }}>
                    Zaloguj się
                </a>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state.user),
        store: getStore(state.store),
        organization: getOrganization(state.menu),
    }
}
export default withTranslation()(connect(mapStateToProps)(RegisterUserComponent))
