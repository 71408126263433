import CookiesStore from "./cookiesStore";

class AgeRestrictedContext {
    static accepted = false;
    static alcohol = false;
    static initialize(disabled){
        if(disabled){
            this.accepted = true;
        }else{
            this.accepted = CookiesStore.get("age_restricted_accepted");
        }
    }
    static setAlcohol(value){
        this.alcohol = value;
    }
    static isAlcohol() {
        return this.alcohol;
    }
    static isAccepted(){
        if(!this.accepted) this.initialize();
        return this.accepted;
    }
    static accept(){
        CookiesStore.set("age_restricted_accepted", true);
        this.accepted = true;
    }
    static isItemEnergyDrink(value){
        return !!(value.custom_fields && value.custom_fields.drink_type && value.custom_fields.drink_type == "energy_drink");
    }
    static isItemAlcohol(value){
        return !!(value.custom_fields && value.custom_fields.drink_type && value.custom_fields.drink_type == "alcohol");
    }
}
export default AgeRestrictedContext;
