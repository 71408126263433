import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../../../application/user/reducer";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as BackSVG} from "../../../../images/icons/arrow-left.svg";
import UserService from "../../../../application/user/userService";
import ErrorFormAlertView from "../../../common/ErrorFormAlertView";
import ErrorFormHelper from "../../../common/ErrorFormHelper";
import ErrorFormView from "../../../common/ErrorFormView";

const UserDeletePage = (props) => {
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const deleteAccount = async () => {
        setLoading(true);
        try {
            let response = await props.dispatch(UserService.delete(password))
            setLoading(false);
            history.push("/");
        } catch (e) {
            setErrors([{
                message: "Nie udało się usunąć konto, hasło niepoprawne."
            }]);
            setLoading(false);
        }

    }
    return (

        <>
            <div className={"user"}>
                <div className={"user-header-top top"}>
                    <>
                        <Link to={"/user"} className={"btn-back"}>
                            <BackSVG/>
                        </Link>
                        <h5 className="">
                            Usuń konto
                        </h5>
                    </>
                </div>
                <div className={"container mt-2"}>
                    <p>
                        Czy na pewno chcesz usunąć konto? Twoje dane zostaną bezpowrotnie usunięte.
                    </p>
                    <div>
                        <ErrorFormAlertView errors={errors}/>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                                   className={"form-control" + ErrorFormHelper(errors, "password")}
                                   placeholder="Twoje haslo"
                                   aria-label="Recipient's username" aria-describedby="cart-password"/>
                            <label htmlFor="cart-username">Twoje haslo</label>
                            <ErrorFormView errors={errors} field="password"/>
                        </div>
                    </div>
                    <button
                        className={"btn-spinner btn-block btn-primary btn w-100 btn-submit" + (loading ? ' loading' : '')}
                        onClick={deleteAccount}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        Usuń konto
                    </button>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    user: getUser(state.user)
})
export default withTranslation()(connect(mapStateToProps)(UserDeletePage))
