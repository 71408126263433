import TagManager from 'react-gtm-module'

let defaultCookieConsent = {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied',
};
const ReactGtag = {

    initializeTagManager: function (id, cookieConsent){
        const tagManagerArgs = {
            gtmId: id
        }
        if(defaultCookieConsent){
            tagManagerArgs.dataLayer = {
                event: 'default_consent',
                consent: defaultCookieConsent,
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(tagManagerArgs.dataLayer);
        }
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        // if(cookieConsent){
        //     window.gtag('consent', 'default', cookieConsent);
        // }
        TagManager.initialize(tagManagerArgs)
        // if(cookieConsent){
        //     this.consent(cookieConsent);
        // }
    },
    initializeTagManager2: function (id){
        if (typeof window === 'undefined') {
            return;
        }
        var gtag = 'gtagManager';
        if (document.getElementById(gtag)) {
            return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'default_consent',
            consent: defaultCookieConsent || 'pending', // Set 'pending' if consent is not resolved yet
        });
        // Load GTM script dynamically
        const gtmScript = document.createElement('script');
        gtmScript.id = gtag;
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
        gtmScript.type = 'text/javascript';
      
        document.head.appendChild(gtmScript);
        
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        if(defaultCookieConsent){
            window.gtag('consent', 'default', defaultCookieConsent);
        }
        window.gtag('config', id, {
            send_page_view: false
        });
    },
    consent: function(cookieConsent){
        if (typeof window === 'undefined') {
            return;
        }
        window.gtag('consent', 'update', cookieConsent);
    },
    initialize: function(id, config, cookieConsent){

        if (typeof window === 'undefined') {
            return;
        }
        var gtag = 'gtag';
        if (document.getElementById(gtag)) {
            return;
        }
        var script = document.createElement('script');
        script.id = gtag;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
        script.type = 'text/javascript';
        script.async = true;
        document.head.appendChild(script);
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        if(defaultCookieConsent){
            window.gtag('consent', 'default', defaultCookieConsent);
        }
        window.gtag('config', id, config);
        // if(cookieConsent){
        //     this.consent(cookieConsent);
        // }
    },
    gtag: function(command, value, params){
        window.gtag(command, value, params);
    },
    ecommerce: function(event, data){
        // console.log("ecommerce", event, data)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: event,
            ecommerce: data
        });
    }
}

export default ReactGtag;
// class ReactGtag {
//     initialize(id, config){
//
//         if (typeof window === 'undefined') {
//             return;
//         }
//         var gtag = 'gtag';
//         if (document.getElementById(gtag)) {
//             return;
//         }
//         var script = document.createElement('script');
//         script.id = gtag;
//         script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
//         script.type = 'text/javascript';
//         script.async = true;
//         document.head.appendChild(script);
//         if (!window.dataLayer) {
//             window.dataLayer = [];
//         }
//         window.gtag = function () {
//             window.dataLayer.push(arguments);
//         };
//         window.gtag('js', new Date());
//         window.gtag('config', id, config);
//     }
//     gtag(command, value, params){
//         window.gtag(command, value, params);
//     }
// }
// module.exports = new ReactGtag();
// export default ReactGtag;
// // export function initialize(id, config) {
// // }
//
//
// // module.exports = ReactGtag;