import Cookies from 'universal-cookie';

class CookiesStore {

    static baseName = "/";
    static cookies = new Cookies();
    static initilized = false;

    static init(baseName){
        this.initilized = true;
        this.baseName = baseName.endsWith('/') ? baseName.slice(0, -1) : baseName;
        this.cookies = new Cookies(null, { path: this.baseName });
    }
    static set(key ,value, config){
        this.cookies.set(key, value, config);
    }
    static get(key){
      return this.cookies.get(key);
    }
    static remove(key){
        this.cookies.remove(key);
    }

}
export default CookiesStore;