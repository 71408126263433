import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "../ErrorFormHelper";
import ErrorFormView from "../ErrorFormView";
import ErrorFormAlertView from "../ErrorFormAlertView";
import {updateOrderData} from "../../../application/cart/cartService";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getCart} from "../../../application/cart/reducer";
import Languages from "../Languages";
import UserService from "../../../application/user/userService";
import {getUser} from "../../../application/user/reducer";
import {getStore} from "../../../application/store/reducer";
import LoginUserComponent from "./components/LoginUser";
import RegisterUserComponent from "./components/RegisterUser";
import AppConfig from "../../../application/common/AppConfig";
import VerificationUserComponent from "./components/VerificationUser";
import LostPasswordUser from "./components/LostPasswordUser";

function LoginUserModal(props) {
    const [page, setPage] = useState(props.page ? props.page : "LOGIN");
    const onShow = () => {}
    const onLogged = () => {
        checkLogin();
    }
    const checkLogin = () => {
        console.log("checkLogin", props.user)
        if(props.user && props.user.name){
            if(props.user.verified){
                props.onHide();
            }else{
                setPage("VERIFICATION");
            }
        }
    }
    const onRegistred = () => {
        checkLogin();
    }
    useEffect(() => {
        checkLogin();
    }, [props.user.verified, props.user.name])

    return(
        <>
            <Modal dialogClassName="modal-full modal-login" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        {/*{page == "REGISTER" && (*/}
                        {/*    <button className={"btn btn-default"} onClick={() => setPage("LOGIN")}>*/}
                        {/*        Wróć*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*{page == "LOST_PASSWORD" && (*/}
                        {/*    <button className={"btn btn-default"} onClick={() => setPage("LOGIN")}>*/}
                        {/*        Wróć*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div className={"login-box"}>
                        {props.store.image_link && (
                            <div className={"login-box-logo"}>
                                <img alt="Logo location" src={props.store.image_link.default}/>
                            </div>
                        )}
                        {page == "LOGIN" && (
                            <>
                                <LoginUserComponent setPage={setPage} onSuccess={onLogged} />
                                {AppConfig.isUserRegistration() && (
                                    <div className={"login-box-register-info"}>
                                        <hr />
                                        <div className={"text-center"}>
                                            Nie posiadasz konta? <a href={"#"} onClick={(e) => {
                                                e.preventDefault();
                                            setPage("REGISTER")
                                        }}>
                                                Załóż konto
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {page == "REGISTER" && (
                            <>
                                <RegisterUserComponent setPage={setPage} onSuccess={onRegistred}/>
                            </>
                        )}
                        {page == "VERIFICATION" && (
                            <>
                                <VerificationUserComponent setPage={setPage} onSuccess={() => props.onHide()}/>
                            </>
                        )}
                        {page == "LOST_PASSWORD" && (
                            <>
                                <LostPasswordUser setPage={setPage} onSuccess={() => props.onHide()}/>
                            </>
                        )}
                        {/*{page == "LOST_PASSWORD" && (*/}
                        {/*    <>*/}
                        {/*        <LostPassword onSuccess={() => setPage("LOGIN")} />*/}
                        {/*    </>*/}
                        {/*)}*/}
                    </div>

                </Modal.Body>
                {/*{page == "LOGIN" && (*/}
                {/*    <Modal.Footer>*/}
                {/*        <>*/}
                {/*            <button className={"btn btn-default w-100"} onClick={() => props.onHide()}>*/}
                {/*                Uzywaj bez logowania*/}
                {/*            </button>*/}
                {/*        </>*/}
                {/*    </Modal.Footer>*/}
                {/*)}*/}
            </Modal>
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
        user: getUser(state.user),
        store: getStore(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(LoginUserModal))
