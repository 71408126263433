
class MenuEditItemStateHelper {

    static getStateModifierGroup = (modifierGroups, modifierGroupId) => {
        if(!modifierGroups) return undefined;
        return modifierGroups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
    }
    static getStateModifierGroupSelectedItem = (modifierGroup, item_id) => {
        if(!modifierGroup){
            return null;
        }
        return modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item_id)[0];
    }
    static getStateModifierGroupSelectedItems = (modifierGroup, item_id) => {
        if(!modifierGroup){
            return [];
        }
        return modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item_id);
    }
    static parseOrderItemToStateItem = (orderItem, parentModifierGroupId) => {
        let modifierGroups = [];
        if(orderItem.modifier_groups){
            orderItem.modifier_groups.forEach(orderItemModifierGroup => {
                let stateModifierGroup = {
                    modifier_group_id: orderItemModifierGroup.modifier_group_id,
                    selected_items: []
                }
                orderItemModifierGroup.selected_items.forEach(selectedItem => {
                    let stateSelectedItem = this.parseOrderItemToStateItem(selectedItem, orderItemModifierGroup.modifier_group_id);
                    stateModifierGroup.selected_items.push(stateSelectedItem);
                })
                modifierGroups.push(stateModifierGroup);
            })
        }
        let stateItem = {
            item_id: orderItem.item_id,
            modifier_groups: modifierGroups,
            name: orderItem.name,
            price: orderItem.base_unit_price_money?.amount,
            quantity: orderItem.quantity,
            total_price: orderItem.total_money?.amount,
            unit_total_price: orderItem.unit_price_money?.amount
        }
        if(parentModifierGroupId){
            stateItem.parent_modifier_group_id = parentModifierGroupId;
        }
        return stateItem;
    }
}
export default MenuEditItemStateHelper;